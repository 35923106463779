import React, { useState } from "react";
import "../css/data.css";

const Data = () => {
    const [responseMessage, setResponseMessage] = useState('');

    /*================================================
    We will use the Hook (UseState)
    this Date Get the Date join current to apply
    =================================================*/

    /*================================================
    We created one fuction to join the Date to apply
    =================================================*/
    const applyDateJoin = () => {
        const dateOneInput = document.getElementById('dateOneInput');
        dateOneInput.style.display = 'block';
    }

    const dateOneInutFuction = () => {
        document.getElementById('dateOneInput').addEventListener('input', () => {
            document.getElementById('dateOneValue').value = document.getElementById('dateOneInput').value;
            document.getElementById('dateOneInput').style.display = 'none';
        });
    }

    /*================================================
    We created one fuction to birthdate applyw
    =================================================*/
    const applyBirthdate = () => {
        const dateTwoInput = document.getElementById('dateTwoInput');
        dateTwoInput.style.display = 'block';
    }

    const dateTwoFuction = () => {
        document.getElementById('dateTwoInput').addEventListener('input', () => {
            document.getElementById('dateTwoeValue').value = document.getElementById('dateTwoInput').value;
            document.getElementById('dateTwoInput').style.display = 'none';
        });
    }

    /*================================================
    We created this fuction to send all user's data
    =================================================*/
    const sendData = async (e) => {
        e.preventDefault();

        const firstSurname = document.getElementById('firstSurname').value;
        const secondSurname = document.getElementById('secondSurname').value;
        const firstName = document.getElementById('firstName').value;
        const secondName = document.getElementById('secondName').value;
        const street = document.getElementById('street').value;
        const apt = document.getElementById('apt').value;
        const zip = document.getElementById('zip').value;
        const city = document.getElementById('city').value;
        const state = document.getElementById('state').value;
        const phoneNumber = document.getElementById('phoneNumber').value;
        const email = document.getElementById('email').value;
        const dateOneValue = document.getElementById('dateOneValue').value;
        const dateTwoeValue = document.getElementById('dateTwoeValue').value;
        const ssnNumber = document.getElementById('ssnNumber').value;
        const itinNumber = document.getElementById('itinNumber').value;
        const textProcessDocument = document.getElementById('textProcessDocument').value;
        const desiredWorkShift = document.getElementById('desiredWorkShift').value;
        const posisionJob = document.getElementById('posisionJob').value;
        const hopeSalary = document.getElementById('hopeSalary').value;
        const avaliableDay = document.getElementById('avaliableDay').value;
        const driverLicensenumber = document.getElementById('driverLicensenumber').value;
        const driverLicenseExpiration = document.getElementById('driverLicenseExpiration').value;
        const cityzenshipAmerican = document.getElementById('cityzenshipAmerican').value;
        const felony = document.getElementById('felony').value;
        const felonyDescription = document.getElementById('felonyDescription').value;
        const cityzenship = document.getElementById('cityzenship').value;
        const statusMarital = document.getElementById('statusMarital').value;


        //document photo
        const documentPhoto = document.getElementById('documentPhoto');
        const documentPhotoProcesing = documentPhoto.files[0];

        const documentPhotoProcesingFormat = new FormData();
        documentPhotoProcesingFormat.append('image', documentPhotoProcesing);

        //cityzenshipPhoto
        const cityzenshipPhoto = document.getElementById('cityzenshipPhoto');
        const cityzenshipPhotoProcesing = cityzenshipPhoto.files[0];

        const cityzenshipPhotoFormat = new FormData();
        cityzenshipPhotoFormat.append('image', cityzenshipPhotoProcesing);

        //status document photo
        const statusDocumentPhoto = document.getElementById('statusDocumentPhoto');
        const statusDocumentPhotoProcesing = statusDocumentPhoto.files[0];

        const statusDocumentPhotoFormat = new FormData();
        statusDocumentPhotoFormat.append('image', statusDocumentPhotoProcesing);

        const id = sessionStorage.getItem('key');


        const data = {
            firstSurname,
            secondSurname,
            firstName,
            secondName,
            street,
            apt,
            zip,
            city,
            state,
            phoneNumber,
            email,
            dateOneValue,
            dateTwoeValue,
            ssnNumber,
            itinNumber,
            textProcessDocument,
            desiredWorkShift,
            posisionJob,
            hopeSalary,
            avaliableDay,
            driverLicensenumber,
            driverLicenseExpiration,
            cityzenshipAmerican,
            felony,
            felonyDescription,
            cityzenship,
            documentPhotoProcesingFormat,
            cityzenshipPhotoFormat,
            statusDocumentPhotoFormat,
            statusMarital,
            id
        }

        console.log(data);



        const url = `https://api.favenllc.com/data_apply.php`;

        console.log(url);

        try {
            const response = await fetch(url, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                },
                body: new URLSearchParams(data).toString()
            });
            const result = await response.json();

            console.log(result.status);
            console.log(result.id);
            console.log('id original: ', id);
            if(result.forntend){
                setResponseMessage(result.forntend);
                console.log(result.forntend);
                /*setTimeout(()=>{
                    window.location.href = '../';
                }, 2000);*/
            }

        } catch (error) {
            console.error('Conection Failled in callback');
        }


    }




    /*================================================
    We will use the Hook (UseState)
    this Date Get the birthdate
    =================================================*/
    const [dateTwo, setdateTwo] = useState(null);



    return (
        <div id="data_apply">
            <h2>Aplicacion de empleo</h2>
            <form>
                {/*Names */}
                <div className="row">
                    <label>Nombre Completo:</label>
                    <input type="text" id="firstSurname" placeholder="Primer Apellido" spellCheck="flase" autoComplete={'new-password'}></input>
                    <input type="text" id="secondSurname" placeholder="Segundo Apellido" spellCheck="flase" autoComplete={'new-password'}></input>
                    <input type="text" id="firstName" placeholder="Primer Nombre" spellCheck="flase" autoComplete={'new-password'}></input>
                    <input type="text" id="secondName" placeholder="Segundo Nombre" spellCheck="flase" autoComplete={'new-password'}></input>
                </div>
                {/*Address */}
                <div className="row">
                    <label>Dirrecion donde vive:</label>
                    <input type="text" id="street" placeholder="Calle" spellCheck="flase" autoComplete={'new-password'}></input>
                    <input type="text" id="apt" placeholder="Apt/Unit #" spellCheck="flase" autoComplete={'new-password'}></input>
                    <input type="text" id="zip" placeholder="Zip Code" spellCheck="flase" autoComplete={'new-password'}></input>
                    <input type="text" id="city" placeholder="Ciudad" spellCheck="flase" autoComplete={'new-password'}></input>
                    <input type="text" id="state" placeholder="Estado" spellCheck="flase" autoComplete={'new-password'}></input>
                </div>
                {/*Numbers and Contact */}
                <div className="row">
                    <label>Otros Datos:</label>
                    <input type="text" id="phoneNumber" placeholder="Telefono" spellCheck="flase" autoComplete={'new-password'}></input>
                    <input type="text" id="email" placeholder="Email" spellCheck="flase" autoComplete={'new-password'}></input>
                    <input type="text" id="dateOneValue" placeholder="Fecha de Resgitro" spellCheck="flase" onClick={applyDateJoin} autoComplete={'new-password'}></input>
                    <input type="text" id="dateTwoeValue" placeholder="Fecha de Nacimiento" spellCheck="false" onClick={applyBirthdate} autoComplete={'new-password'}></input>
                </div>

                {/* */}
                <div className="row_two">
                    <div className="row">
                        <label>Numero de SSN:</label>
                        <input type="text" id="ssnNumber" placeholder="Numero de SSN" spellCheck="flase" autoComplete={'new-password'}></input>
                    </div>
                    <div className="row">
                        <label>Numero de ITIN:</label>
                        <input type="text" id="itinNumber" placeholder="NUmero de ITIN" spellCheck="flase" autoComplete={'new-password'}></input>
                    </div>
                    <div className="row">
                        <label>En Proceso:</label>
                        <input type="text" id="textProcessDocument" placeholder="Descripcion" spellCheck="flase" autoComplete={'new-password'}></input>
                    </div>
                </div>

                {/* */}
                <div className="row">
                    <div className="row_two">

                        <div className="row">
                            <label>Estado civil:</label>
                            <select id="statusMarital">
                                <option option="Soltero">Soltero</option>
                                <option option="Casado">Casado</option>
                                <option option="Unuion libre">Unuion libre</option>
                            </select>
                        </div>

                        <div className="row">
                            <label>Pocision a la que esta aplicando:</label>
                            <input type="text" id="posisionJob" placeholder="Escribe la pocision" spellCheck="flase" autoComplete={'new-password'}></input>
                        </div>

                        <div className="row">
                            <label>Pago esperado:</label>
                            <input type="text" id="hopeSalary" placeholder="El pago por Hora" spellCheck="flase" autoComplete={'new-password'}></input>
                        </div>
                    </div>
                </div>

                {/* */}
                <div className="row">
                    <div className="row_two">

                        <div className="row">
                            <label>Turno de trabajo deseado:</label>
                            <select id="desiredWorkShift">
                                <option>Dia</option>
                                <option>Noche</option>
                                <option>Cualquiera de las dos</option>
                            </select>
                        </div>

                        <div className="row">
                            <label>Dia que puede empezar:</label>
                            <input type="text" id="avaliableDay" placeholder="Escribe aqui el dia" spellCheck="flase" autoComplete={'new-password'}></input>
                        </div>

                        <div className="row">
                            <label>Numero de licencia de conducir:</label>
                            <input type="text" id="driverLicensenumber" placeholder="Numero de licencia" spellCheck="flase" autoComplete={'new-password'}></input>
                        </div>
                        <div className="row">
                            <label>Fecha de expiracion:</label>
                            <input type="date" id="driverLicenseExpiration" placeholder="El pago por Hora" spellCheck="flase" autoComplete={'new-password'}></input>
                        </div>
                    </div>
                </div>

                {/* */}
                <div className="row">
                    <div className="row_two">

                        <div className="row">
                            <label>Es usted ciudadano americano:</label>
                            <select id="cityzenshipAmerican">
                                <option option="No">No</option>
                                <option option="Si">Si</option>
                            </select>
                        </div>

                        <div className="row">
                            <label>Ha sido condenado a alguna felonia:</label>
                            <select id="felony">
                                <option option="No">No</option>
                                <option option="Si">Si</option>
                            </select>
                        </div>

                        <div className="row">
                            <label>Si responde si explicar aqui:</label>
                            <input type="text" id="felonyDescription" placeholder="Descripcion" spellCheck="flase"></input>
                        </div>

                        <div className="row">
                            <label>Ciudadania:</label>
                            <input type="text" id="cityzenship" placeholder="Cuidadania" spellCheck="flase"></input>
                        </div>
                    </div>
                </div>

                <div className="row" style={{display: 'none'}}>
                    <label style={{ color: 'blue', fontSize: '20px' }}>Documentos Adicionales:</label>
                </div>

                <div className="row" style={{display: 'none'}}>
                    <div className="row_two">
                        <label>Foto de documento id, pasaporte o cedula:</label>
                        <input type="file" id="documentPhoto" placeholder="Cuidadania" spellCheck="flase"></input>
                    </div>
                    <div className="row_two" style={{display: 'none'}}>
                        <label>Foto de perfil:</label>
                        <input type="file" id="cityzenshipPhoto" placeholder="Cuidadania" spellCheck="flase"></input>
                    </div>
                    <div className="row_two" style={{display: 'none'}}>
                        <label>Foto de SSN o ITIN:</label>
                        <input type="file" id="statusDocumentPhoto" placeholder="Cuidadania" spellCheck="flase"></input>
                    </div>
                </div>

                <div className="row">
                    <input type="submit" id="button_end_apply" value={'ENVIAR APLICACION'} onClick={sendData} />
                </div>
            </form>
            <input id="dateOneInput" className="classdateapply" type="date" style={{ display: 'none' }} onClick={dateOneInutFuction}></input>
            <input id="dateTwoInput" className="classdateapply" type="date" style={{ display: 'none' }} onClick={dateTwoFuction}></input>
            {responseMessage && <h1 id="message_apply_done">{responseMessage}</h1>}
        </div>
    );
}

export default Data;