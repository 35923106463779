import React from "react";
import "../css/main.css";
import { NavLink } from "react-router-dom";

const Main = () => {
    return (
        <div id="main">
            {/*Img Logo*/}
            <div id='img_logo_app'>

            </div>

            <h2 id='h2_app'>Aplicacion de Empleo</h2>

            <NavLink id="button_app" to={"/Apply"}>Aplicar</NavLink>

        </div>
    );
}

export default Main;