import logo from './logo.svg';
import './App.css';
import Main from './components/Main';
import { BrowserRouter, NavLink, Route, Routes } from "react-router-dom";
import Apply from "./components/Apply";

function App() {
  return (
    <div id='app'>
      <BrowserRouter>
        <Routes>
        <Route id="button_app" path="/" element={<Main/>} />
          <Route id="button_app" path="/Apply" element={<Apply />} />
        </Routes>

      </BrowserRouter>
    </div>
  );
}

export default App;
