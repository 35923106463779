import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import "../css/apply.css";
import logo from "../img/pixelcut-export.jpeg";
import Data from "./Data";

const Apply = () => {
    const [result, setResult] = useState(null);
    const [load, setLoad] = useState(null);

    const exists_apply = async () => {

        setLoad(1);

        const document_apply = document.getElementById('document_apply').value;
        const response = await fetch(`https://api.favenllc.com/exist_apply.php?document=${document_apply}`);
        const result = await response.json();
        if (result.status === 'success') {
            sessionStorage.setItem('key', result.key);
            setResult(1);
            setLoad(2);
        } else {
            console.log('fail');
            setResult(2);
            setLoad(2);
        }
    }


    const return_Component = () => {
        if (result === 1) {
            return (<Data />);
        } else if (result === 2) {
            return (<h2 className="h2_none_apply">Lo sentimos este documento no esta en la base de datos.</h2>);
        } else if (load === 1) {
            return (<h2 className="h2_none_apply">Cargando...</h2>);
        }
        return <h2 className="h2_none_apply">Escribe el documento en la parte de arriba a la derecha para continuar.</h2>;
    }

    return (
        <div id="apply">
            {/*Header*/}
            <header id="header_apply">
                <NavLink to={"/"}>
                    <img src={logo} alt="Logo" />
                </NavLink>

                <div id="login_apply">
                    <input
                        type="text"
                        id="document_apply"
                        placeholder="Documento"
                        spellCheck="false"
                        autoComplete={'new-password'}
                    />
                    <button onClick={exists_apply}>Ir</button>
                </div>

            </header>

            {return_Component()}
        </div>
    );
}

export default Apply;
